<template>
  <div class="login">
    <div class="login-top">
      <van-image
        width="60"
        height="60"
        :src="require('../assets/微信图片_20240423152137.png')"
      />
      <div class="login-text">欢迎来到淘星妍</div>
    </div>
    <div class="login-bottom">
    <van-form  @submit="onSubmit">
      <van-field
        v-model="mobile"
        name="pattern"
        left-icon="phone-o"
        placeholder="请输入手机号"
        clearable
        :rules="[{ required: true, pattern}]"
      />
      <van-field
        v-model="password"
        type="password"
        left-icon="closed-eye"
        placeholder="密码"
        clearable
        minlength = 6
        :rules="[{ required: true}]"
      />
      <div style="margin: 30px">
        <van-button round block 
        color="#ffdd14"
        :disabled="btDisabled"
        native-type="submit"
          >登录</van-button
        >
      </div>
    </van-form>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { getPhoneLogin } from "../api/index";
import { setUserToken } from "../untils/localStorage";
export default {
  data() {
    return {
      mobile: "",
      password: "", 
      pattern:/^[1][3-9]\d{9}$/,
      btDisabled:false
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit(){
      this.dfred()
    },
    dfred() {
      this.btDisabled = true
      getPhoneLogin(this.mobile, this.password).then(res=>{
        if (res.status == '200') {
        let { accessToken } = res.data.data;
        setUserToken(accessToken);
        //储存token之后
        this.$router.push('/index')
        this.$tools.setTimeMiao(()=>{
          this.btDisabled = false
          },2000)
        // 储存个人Token信息
        }else{
          this.$tools.setTimeMiao(()=>{
          this.btDisabled = false
          },2000)
        }
      }).catch(()=>{
        Toast.fail('手机号或密码错误')
        this.$tools.setTimeMiao(()=>{
          this.btDisabled = false
          },2000)
      })
    }
  }
};
</script>
<style lang="less" scoped>
.login {
  width: auto;
  height: 100vh;
  box-sizing: border-box;
  .login-top{
    height: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: left;
    background-image: url('../assets/bj_20240423141613.jpg');
  }
  .loginTop {
    margin-top: 1rem;
    font-size: 1rem;
    color: rgb(33, 33, 33);
    margin-bottom: 20px;
  }

  .loginContent {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;
    input {
      border: none;
      font-size: 18px;
      color: #999999;
      height: 40px;
    }
    .btn {
      width: 60%;
      height: 40px;
      color: white;
      border: none;
      text-align: center;
      line-height: 40px;
      border-radius: 4px;
      background-color: rgb(192, 1, 1);
    }
  }
  .login-bottom{
    padding: 20px 20px;
    border-top-left-radius: 20px; /* 左上角圆角半径 */
    border-top-right-radius: 20px; /* 右上角圆角半径 */
    box-shadow: 0px -8px 10px rgba(255, 242, 177, 0.5);
    
  }
}
.van-button__text{
  color: #505050;
}
.login-text{
  color: #505050;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 600;
}
</style>
