// 引入request
import request from '../untils/request'
//获取用户详情
// 定义Login登录请求
export function getPhoneLogin(mobile, password) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/login/saler',
      data: {
        mobile,
        password,
      },
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}